//#region Google Authenticate Url

const googleClientId = "278162669363-b4i80r2drqsg24l10ddtbk1tq5ahhkov.apps.googleusercontent.com";
//const googleRedirectUrl = "http://localhost:3000/auth/google";
const googleRedirectUrl = "https://tripdev.onviqa.com/auth/google";
//const googleRedirectUrl = "https://tripbeta.onviqa.com/auth/google";

export function googleAuthenticateUrl(): any {
    return `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20openid%20profile%20email&openid.realm&redirect_uri=${googleRedirectUrl}&access_type=offline&prompt=consent`;
};

//#endregion Google Authenticate Url

//#region Facebook Authenticate Url

const facebookClientId = "671962921235467";
//const facebookRedirectUrl = "http://localhost:3000/auth/facebook";
const facebookRedirectUrl = "https://tripdev.onviqa.com/auth/facebook";
//const facebookRedirectUrl = "https://tripbeta.onviqa.com/auth/facebook";

export function facebookAuthenticateUrl(): any {
    return `https://www.facebook.com/v17.0/dialog/oauth?client_id=${facebookClientId}&display=popup&response_type=token&redirect_uri=${facebookRedirectUrl}&scope=email`;
};

//#endregion Facebook Authenticate Url