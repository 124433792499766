import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useFetchTripList = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("trip?pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize + "&filterType=" + data.filterType + "&searchFilter=" + data.searchFilter);
    }, options);
};

export const useGetTripDetail = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("trip/" + data.tripId);
    }, options);
};

export const useFetchUserLocation = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("user/location?userIds=" + data.userIds);
    }, options);
};

export const useTripCancel = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("trip/trip-cancel", data);
    }, options);
};

export const useTripDelete = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("trip/trip-delete", data);
    }, options);
};

export const useTripScheduleAgentReject = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("trip/schedule/agent/reject", data);
    }, options);
};

export const useFetchTripCancellationCharge = (tripId: string | undefined, options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get(`trip/${tripId}/cancellation-charge?scheduleId=${data.scheduleId}`);
    }, options);
};

export const useUpdatePaymentRefund = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("trip-payment/refund?tripId=" + data.tripId + "&scheduleId=" + data.scheduleId, data);
    }, options);
};